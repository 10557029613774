// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dienstleistungen-de-js": () => import("./../../../src/pages/dienstleistungen.de.js" /* webpackChunkName: "component---src-pages-dienstleistungen-de-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preise-de-js": () => import("./../../../src/pages/preise.de.js" /* webpackChunkName: "component---src-pages-preise-de-js" */),
  "component---src-pages-pricing-en-js": () => import("./../../../src/pages/pricing.en.js" /* webpackChunkName: "component---src-pages-pricing-en-js" */),
  "component---src-pages-services-en-js": () => import("./../../../src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-services-fr-js": () => import("./../../../src/pages/services.fr.js" /* webpackChunkName: "component---src-pages-services-fr-js" */),
  "component---src-pages-servizi-it-js": () => import("./../../../src/pages/servizi.it.js" /* webpackChunkName: "component---src-pages-servizi-it-js" */),
  "component---src-pages-tariffe-it-js": () => import("./../../../src/pages/tariffe.it.js" /* webpackChunkName: "component---src-pages-tariffe-it-js" */),
  "component---src-pages-tarifs-fr-js": () => import("./../../../src/pages/tarifs.fr.js" /* webpackChunkName: "component---src-pages-tarifs-fr-js" */)
}

